<template>
	<div class="programs-wrapper  col-xs-12 col-sm-6 col-md-6 col-lg-3">
		<h3 class="program-period">{{ period.title }}</h3>
		<div class="program-list" v-if="period.programList.length">
			<div
				v-for="program in period.programList"
				:class="{ program: true, active: program.isActive, shown: program.isShown }"
				:key="program.start"
			>
				<div class="program-time">
					<h4 v-if="program.isActive">В эфире</h4>
					<div class="time">
						{{ program.hour + ':' + program.minute }}
					</div>
				</div>
				<div class="program-info">
					<h3 class="program-title">
						{{ program.title }}
					</h3>
					<div class="active-program-info" v-if="program.description || program.isActive">
						<program-tooltip v-if="!!program.description" :description="program.description" />

						<program-play @openStream="openStream" v-if="!!program.isActive" />
					</div>
				</div>
			</div>
		</div>
		<div class="porgram-list" v-else>
			Нет расписания
		</div>
	</div>
</template>

<script>
const programPlay = () => import('./programPlay');
const programTooltip = () => import('./programTooltip');

export default {
	props: {
		period: {
			type: Object,
			required: true,
		},
	},
	components: {
		programTooltip,
		programPlay,
	},
	methods: {
		openStream() {
			const offset = 30 + window.innerWidth > 992 ? 80 : window.innerWidth > 767 ? 70 : 50;
			const player = document.getElementById('player');
			const offsetTop = player.offsetTop - offset - 20;

			const params = {
				top: offsetTop,
			};
			if ('scrollBehavior' in document.body.style) {
				params.behavior = 'smooth';
			}
			window.scrollTo(params);
		},
	},
};
</script>
